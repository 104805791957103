<template>
  <!-- eslint-disable max-lines -->
  <svg
    id="map"
    data-dot="8"
    height="200"
    viewBox="0 0 232 232"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#ffffff"
      height="232"
      width="232"
      x="0"
      y="0"
    />
    <defs />
    <g fill="#000000">
      <rect
        height="24"
        transform="rotate(-90,44,188)"
        width="24"
        x="32"
        y="176"
      />
      <path
        class="svgeye"
        clip-rule="evenodd"
        d="M 16 160v 56h 56v -56zM 24 168h 40v 40h -40z"
        transform="rotate(-90,44,188)"
      />
      <rect
        height="24"
        transform="rotate(90,188,44)"
        width="24"
        x="176"
        y="32"
      />
      <path
        class="svgeye"
        clip-rule="evenodd"
        d="M 160 16v 56h 56v -56zM 168 24h 40v 40h -40z"
        transform="rotate(90,188,44)"
      />
      <rect
        height="24"
        transform="rotate(0,44,44)"
        width="24"
        x="32"
        y="32"
      />
      <path
        class="svgeye"
        clip-rule="evenodd"
        d="M 16 16v 56h 56v -56zM 24 24h 40v 40h -40z"
        transform="rotate(0,44,44)"
      />
      <rect
        height="8"
        transform="rotate(0,92,20)"
        width="8"
        x="88"
        y="16"
      />
      <rect
        height="8"
        transform="rotate(0,108,20)"
        width="8"
        x="104"
        y="16"
      />
      <rect
        height="8"
        transform="rotate(0,116,20)"
        width="8"
        x="112"
        y="16"
      />
      <rect
        height="8"
        transform="rotate(0,132,20)"
        width="8"
        x="128"
        y="16"
      />
      <rect
        height="8"
        transform="rotate(0,148,20)"
        width="8"
        x="144"
        y="16"
      />
      <rect
        height="8"
        transform="rotate(0,84,28)"
        width="8"
        x="80"
        y="24"
      />
      <rect
        height="8"
        transform="rotate(0,100,28)"
        width="8"
        x="96"
        y="24"
      />
      <rect
        height="8"
        transform="rotate(0,108,28)"
        width="8"
        x="104"
        y="24"
      />
      <rect
        height="8"
        transform="rotate(0,84,36)"
        width="8"
        x="80"
        y="32"
      />
      <rect
        height="8"
        transform="rotate(0,100,36)"
        width="8"
        x="96"
        y="32"
      />
      <rect
        height="8"
        transform="rotate(0,124,36)"
        width="8"
        x="120"
        y="32"
      />
      <rect
        height="8"
        transform="rotate(0,132,36)"
        width="8"
        x="128"
        y="32"
      />
      <rect
        height="8"
        transform="rotate(0,140,36)"
        width="8"
        x="136"
        y="32"
      />
      <rect
        height="8"
        transform="rotate(0,116,44)"
        width="8"
        x="112"
        y="40"
      />
      <rect
        height="8"
        transform="rotate(0,92,52)"
        width="8"
        x="88"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,108,52)"
        width="8"
        x="104"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,116,52)"
        width="8"
        x="112"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,124,52)"
        width="8"
        x="120"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,132,52)"
        width="8"
        x="128"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,140,52)"
        width="8"
        x="136"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,148,52)"
        width="8"
        x="144"
        y="48"
      />
      <rect
        height="8"
        transform="rotate(0,100,60)"
        width="8"
        x="96"
        y="56"
      />
      <rect
        height="8"
        transform="rotate(0,108,60)"
        width="8"
        x="104"
        y="56"
      />
      <rect
        height="8"
        transform="rotate(0,132,60)"
        width="8"
        x="128"
        y="56"
      />
      <rect
        height="8"
        transform="rotate(0,84,68)"
        width="8"
        x="80"
        y="64"
      />
      <rect
        height="8"
        transform="rotate(0,100,68)"
        width="8"
        x="96"
        y="64"
      />
      <rect
        height="8"
        transform="rotate(0,116,68)"
        width="8"
        x="112"
        y="64"
      />
      <rect
        height="8"
        transform="rotate(0,132,68)"
        width="8"
        x="128"
        y="64"
      />
      <rect
        height="8"
        transform="rotate(0,148,68)"
        width="8"
        x="144"
        y="64"
      />
      <rect
        height="8"
        transform="rotate(0,92,76)"
        width="8"
        x="88"
        y="72"
      />
      <rect
        height="8"
        transform="rotate(0,124,76)"
        width="8"
        x="120"
        y="72"
      />
      <rect
        height="8"
        transform="rotate(0,132,76)"
        width="8"
        x="128"
        y="72"
      />
      <rect
        height="8"
        transform="rotate(0,148,76)"
        width="8"
        x="144"
        y="72"
      />
      <rect
        height="8"
        transform="rotate(0,28,84)"
        width="8"
        x="24"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,36,84)"
        width="8"
        x="32"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,44,84)"
        width="8"
        x="40"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,60,84)"
        width="8"
        x="56"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,68,84)"
        width="8"
        x="64"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,108,84)"
        width="8"
        x="104"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,116,84)"
        width="8"
        x="112"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,124,84)"
        width="8"
        x="120"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,132,84)"
        width="8"
        x="128"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,148,84)"
        width="8"
        x="144"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,196,84)"
        width="8"
        x="192"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,204,84)"
        width="8"
        x="200"
        y="80"
      />
      <rect
        height="8"
        transform="rotate(0,20,92)"
        width="8"
        x="16"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,44,92)"
        width="8"
        x="40"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,52,92)"
        width="8"
        x="48"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,60,92)"
        width="8"
        x="56"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,76,92)"
        width="8"
        x="72"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,100,92)"
        width="8"
        x="96"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,116,92)"
        width="8"
        x="112"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,148,92)"
        width="8"
        x="144"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,172,92)"
        width="8"
        x="168"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,204,92)"
        width="8"
        x="200"
        y="88"
      />
      <rect
        height="8"
        transform="rotate(0,36,100)"
        width="8"
        x="32"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,60,100)"
        width="8"
        x="56"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,68,100)"
        width="8"
        x="64"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,76,100)"
        width="8"
        x="72"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,84,100)"
        width="8"
        x="80"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,92,100)"
        width="8"
        x="88"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,100,100)"
        width="8"
        x="96"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,132,100)"
        width="8"
        x="128"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,148,100)"
        width="8"
        x="144"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,164,100)"
        width="8"
        x="160"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,172,100)"
        width="8"
        x="168"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,180,100)"
        width="8"
        x="176"
        y="96"
      />
      <rect
        height="8"
        transform="rotate(0,20,108)"
        width="8"
        x="16"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,44,108)"
        width="8"
        x="40"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,60,108)"
        width="8"
        x="56"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,76,108)"
        width="8"
        x="72"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,92,108)"
        width="8"
        x="88"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,116,108)"
        width="8"
        x="112"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,124,108)"
        width="8"
        x="120"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,132,108)"
        width="8"
        x="128"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,164,108)"
        width="8"
        x="160"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,180,108)"
        width="8"
        x="176"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,188,108)"
        width="8"
        x="184"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,196,108)"
        width="8"
        x="192"
        y="104"
      />
      <rect
        height="8"
        transform="rotate(0,20,116)"
        width="8"
        x="16"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,28,116)"
        width="8"
        x="24"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,44,116)"
        width="8"
        x="40"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,52,116)"
        width="8"
        x="48"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,68,116)"
        width="8"
        x="64"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,76,116)"
        width="8"
        x="72"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,108,116)"
        width="8"
        x="104"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,116,116)"
        width="8"
        x="112"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,156,116)"
        width="8"
        x="152"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,164,116)"
        width="8"
        x="160"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,180,116)"
        width="8"
        x="176"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,196,116)"
        width="8"
        x="192"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,204,116)"
        width="8"
        x="200"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,212,116)"
        width="8"
        x="208"
        y="112"
      />
      <rect
        height="8"
        transform="rotate(0,36,124)"
        width="8"
        x="32"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,44,124)"
        width="8"
        x="40"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,52,124)"
        width="8"
        x="48"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,60,124)"
        width="8"
        x="56"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,84,124)"
        width="8"
        x="80"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,100,124)"
        width="8"
        x="96"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,108,124)"
        width="8"
        x="104"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,140,124)"
        width="8"
        x="136"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,148,124)"
        width="8"
        x="144"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,156,124)"
        width="8"
        x="152"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,164,124)"
        width="8"
        x="160"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,172,124)"
        width="8"
        x="168"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,180,124)"
        width="8"
        x="176"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,212,124)"
        width="8"
        x="208"
        y="120"
      />
      <rect
        height="8"
        transform="rotate(0,28,132)"
        width="8"
        x="24"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,44,132)"
        width="8"
        x="40"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,68,132)"
        width="8"
        x="64"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,76,132)"
        width="8"
        x="72"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,108,132)"
        width="8"
        x="104"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,116,132)"
        width="8"
        x="112"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,124,132)"
        width="8"
        x="120"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,148,132)"
        width="8"
        x="144"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,156,132)"
        width="8"
        x="152"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,164,132)"
        width="8"
        x="160"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,180,132)"
        width="8"
        x="176"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,196,132)"
        width="8"
        x="192"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,204,132)"
        width="8"
        x="200"
        y="128"
      />
      <rect
        height="8"
        transform="rotate(0,20,140)"
        width="8"
        x="16"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,44,140)"
        width="8"
        x="40"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,52,140)"
        width="8"
        x="48"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,60,140)"
        width="8"
        x="56"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,76,140)"
        width="8"
        x="72"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,84,140)"
        width="8"
        x="80"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,148,140)"
        width="8"
        x="144"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,156,140)"
        width="8"
        x="152"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,172,140)"
        width="8"
        x="168"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,180,140)"
        width="8"
        x="176"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,212,140)"
        width="8"
        x="208"
        y="136"
      />
      <rect
        height="8"
        transform="rotate(0,52,148)"
        width="8"
        x="48"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,68,148)"
        width="8"
        x="64"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,76,148)"
        width="8"
        x="72"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,124,148)"
        width="8"
        x="120"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,140,148)"
        width="8"
        x="136"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,148,148)"
        width="8"
        x="144"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,156,148)"
        width="8"
        x="152"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,164,148)"
        width="8"
        x="160"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,172,148)"
        width="8"
        x="168"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,180,148)"
        width="8"
        x="176"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,188,148)"
        width="8"
        x="184"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,196,148)"
        width="8"
        x="192"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,204,148)"
        width="8"
        x="200"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,212,148)"
        width="8"
        x="208"
        y="144"
      />
      <rect
        height="8"
        transform="rotate(0,84,156)"
        width="8"
        x="80"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,100,156)"
        width="8"
        x="96"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,108,156)"
        width="8"
        x="104"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,116,156)"
        width="8"
        x="112"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,124,156)"
        width="8"
        x="120"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,148,156)"
        width="8"
        x="144"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,180,156)"
        width="8"
        x="176"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,196,156)"
        width="8"
        x="192"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,212,156)"
        width="8"
        x="208"
        y="152"
      />
      <rect
        height="8"
        transform="rotate(0,100,164)"
        width="8"
        x="96"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,108,164)"
        width="8"
        x="104"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,124,164)"
        width="8"
        x="120"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,132,164)"
        width="8"
        x="128"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,148,164)"
        width="8"
        x="144"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,164,164)"
        width="8"
        x="160"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,180,164)"
        width="8"
        x="176"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,196,164)"
        width="8"
        x="192"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,204,164)"
        width="8"
        x="200"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,212,164)"
        width="8"
        x="208"
        y="160"
      />
      <rect
        height="8"
        transform="rotate(0,84,172)"
        width="8"
        x="80"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,92,172)"
        width="8"
        x="88"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,116,172)"
        width="8"
        x="112"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,124,172)"
        width="8"
        x="120"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,132,172)"
        width="8"
        x="128"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,148,172)"
        width="8"
        x="144"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,180,172)"
        width="8"
        x="176"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,204,172)"
        width="8"
        x="200"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,212,172)"
        width="8"
        x="208"
        y="168"
      />
      <rect
        height="8"
        transform="rotate(0,92,180)"
        width="8"
        x="88"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,116,180)"
        width="8"
        x="112"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,124,180)"
        width="8"
        x="120"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,132,180)"
        width="8"
        x="128"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,148,180)"
        width="8"
        x="144"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,156,180)"
        width="8"
        x="152"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,164,180)"
        width="8"
        x="160"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,172,180)"
        width="8"
        x="168"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,180,180)"
        width="8"
        x="176"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,188,180)"
        width="8"
        x="184"
        y="176"
      />
      <rect
        height="8"
        transform="rotate(0,84,188)"
        width="8"
        x="80"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,100,188)"
        width="8"
        x="96"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,116,188)"
        width="8"
        x="112"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,124,188)"
        width="8"
        x="120"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,148,188)"
        width="8"
        x="144"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,156,188)"
        width="8"
        x="152"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,164,188)"
        width="8"
        x="160"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,180,188)"
        width="8"
        x="176"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,188,188)"
        width="8"
        x="184"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,196,188)"
        width="8"
        x="192"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,204,188)"
        width="8"
        x="200"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,212,188)"
        width="8"
        x="208"
        y="184"
      />
      <rect
        height="8"
        transform="rotate(0,84,196)"
        width="8"
        x="80"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,92,196)"
        width="8"
        x="88"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,100,196)"
        width="8"
        x="96"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,108,196)"
        width="8"
        x="104"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,140,196)"
        width="8"
        x="136"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,148,196)"
        width="8"
        x="144"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,156,196)"
        width="8"
        x="152"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,164,196)"
        width="8"
        x="160"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,180,196)"
        width="8"
        x="176"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,196,196)"
        width="8"
        x="192"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,204,196)"
        width="8"
        x="200"
        y="192"
      />
      <rect
        height="8"
        transform="rotate(0,84,204)"
        width="8"
        x="80"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,92,204)"
        width="8"
        x="88"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,100,204)"
        width="8"
        x="96"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,156,204)"
        width="8"
        x="152"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,180,204)"
        width="8"
        x="176"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,196,204)"
        width="8"
        x="192"
        y="200"
      />
      <rect
        height="8"
        transform="rotate(0,92,212)"
        width="8"
        x="88"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,124,212)"
        width="8"
        x="120"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,156,212)"
        width="8"
        x="152"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,172,212)"
        width="8"
        x="168"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,180,212)"
        width="8"
        x="176"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,188,212)"
        width="8"
        x="184"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,196,212)"
        width="8"
        x="192"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,204,212)"
        width="8"
        x="200"
        y="208"
      />
      <rect
        height="8"
        transform="rotate(0,212,212)"
        width="8"
        x="208"
        y="208"
      />
    </g>
  </svg>
</template>
