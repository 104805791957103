<i18n>
ru:
  error: ERROR
  title: Отсканируйте QR-код, чтобы перейти к скачиванию мобильного приложения.
ua:
  error: ERROR
  title: Відскануйте QR-код, щоб перейти до завантаження мобільного додатку.
us:
  error: ERROR
  title: Scan the QR code to access the mobile app.
</i18n>

<template>
  <div class="v-d-flex v-flex-column v-qr-app">
    <div
      class="v-qr-app--title"
      v-html="translate('qrAppPopup.title')"
    />
    <div
      v-if="stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AppDownloadQRBase64)"
      class="v-qr-app--qr-error v-elements-overlay"
    >
      <div class="v-overlay-element">
        <icon-general-qr-code-example />
      </div>
      <div class="v-overlay-element v-d-flex v-justify-content-center v-align-items-center">
        <div
          class="v-qr-app--qr-error-text"
          v-html="translate('qrAppPopup.error')"
        />
      </div>
    </div>
    <img
      v-else
      class="v-img-fluid"
      :alt="translate('qrPayPopup.overQr')"
      :src="`data:image/png; base64, ${appConfig.VueSettingsPreRun.AppDownloadQRBase64}`"
    />
  </div>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-qr-app {
  gap: 1.15rem;

  &--title {
    font-size: 1.15rem;
  }

  &--qr-error {
    svg {
      width: 220px;
      height: 220px;
    }
  }

  .v-qr-app--qr-error-text {
    width: 100%;
    background: variables.$PopupBackground;
    text-align: center;
    padding: 1.1rem;
    font-size: 2.2rem;
  }
}
</style>
